import { v4 as uuidv4 } from 'uuid';

const servicesFourData = [
    {
        id: uuidv4(),
        icon: 'coding',
        title: 'Web Development',
        desc: 'Specializing in creating dynamic, responsive websites that enhance user experience and drive business growth.',
    },

    {
        id: uuidv4(),
        icon: 'bullhorn',
        title: 'Digital Marketing',
        desc: 'Strategically leveraging digital channels to increase brand visibility, engage target audiences, and boost sales.',
    },

    {
        id: uuidv4(),
        icon: 'writing',
        title: 'SEO & Content Writing',
        desc: 'Optimizing content for search engines to improve website visibility and attract organic traffic.',
    },

    {
        id: uuidv4(),
        icon: 'curve',
        title: 'UI/UX Design',
        desc: 'Designing intuitive and engaging user interfaces and experiences that meet user`s needs and exceed expectations.',
    },

    {
        id: uuidv4(),
        icon: 'brainstorming',
        title: 'Consultancy',
        desc: 'Providing expert advice and strategic planning to help businesses navigate challenges and achieve their goals.',
    },
]

export default servicesFourData;
