import {v4 as uuidv4} from 'uuid';
import img1 from '../../assets/img/home3/quotes.png';

const TestimonialTwoData = [
    {
        id: uuidv4(),
        img:img1,
        name: 'CEO, Mars Info Tech',
        designation: 'Founder',
        speech: 'Mars Info Tech has been revolutionizing the tech industry for over a decade. Our innovative solutions have helped businesses of all sizes thrive in the digital age.',
    },

    {
        id: uuidv4(),
        img:img1,
        name: 'John Doe',
        designation: 'CTO, XYZ Corp',
        speech: 'Working with Mars Info Tech has been a game-changer for our company. Their cutting-edge technology and exceptional customer service have significantly boosted our operational efficiency.',
    },

    {
        id: uuidv4(),
        img:img1,
        name: 'Jane Smith',
        designation: 'Marketing Director, ABC Inc',
        speech: 'Mars Info Tech`s digital marketing solutions have been instrumental in our success. Their team`s expertise and creativity have helped us reach new heights in our market.',
    },
    
]

export default TestimonialTwoData;