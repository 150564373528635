import React from 'react'
import { Link } from 'react-router-dom'

const MobileMenu = ({mobileMenu, handleMobileMenu}) => {
  return (
    <div className={mobileMenu ? 'mobile-nav' : 'mobile-nav show'}>
        <button onClick={handleMobileMenu} type="button" className="close-nav">
            <i className="fal fa-times-circle"></i>
        </button>
        <nav className="sidebar-nav">
            <ul className="metismenu" id="mobile-menu">
                <li><Link to="/">Home</Link></li>
                <li><Link to="/contact">Contact</Link></li>
            </ul>
        </nav>

        <div className="action-bar">
            <a href="mailto:marstechinfodxb@gmail.com"><i className="fal fa-envelope"></i>marstechinfodxb@gmail.com</a>
            <Link to='/contact' className="d-btn theme-btn">Contact Us</Link>
        </div>
    </div>    
     
  )
}

export default MobileMenu