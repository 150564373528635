import {v4 as uuidv4} from 'uuid';
import portfolio1 from '../../assets/img/home4/p1.jpg';
import portfolio2 from '../../assets/img/home4/p2.jpg';
import portfolio3 from '../../assets/img/home4/p3.jpg';
import portfolio4 from '../../assets/img/home4/p4.jpg';
import portfolio5 from '../../assets/img/home4/p5.jpg';

const portfolioFourData = [
    {
        id: uuidv4(),
        img: portfolio1, // Replace with the actual image path
        category: 'Web Development',
        title: 'E-commerce Platform',
    },

    {
        id: uuidv4(),
        img:portfolio2,
        category: 'Digital Marketing',
        title: 'Social Media Campaign',
    },

    {
        id: uuidv4(),
        img:portfolio3,
        category: 'SEO & Content Writing',
        title: 'Content Strategy for B2B',
    },

    {
        id: uuidv4(),
        img:portfolio4,
        category: 'UI/UX Design',
        title: 'Mobile App Redesign',
    },

    {
        id: uuidv4(),
        img:portfolio5,
        category: 'Consultancy',
        title: 'Business Process Optimization',
    },
]

export default portfolioFourData;